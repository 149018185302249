/* ==========================================================================
   Reports
   ========================================================================== */

.c-report {
    margin: 0 auto var(--grid-padding);
}

/*
   Nav
   ========================================================================== */

.c-report-tab-nav {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0 0 calc(var(--grid-padding) + 20px);
    padding: 0;
    list-style: none;
}

.c-report-tab-nav__item {
    display: flex;
    max-width: 280px;

    + .c-report-tab-nav__item {
        margin-left: var(--grid-padding);
    }
}

.c-report-tab-nav__trigger {
    appearance: none;
    padding: 20px;
    border: 1px solid var(--colour-alpha);
    border-radius: 64px;
    background: transparent;
    font-size: 20px;
    font-weight: var(--font-weight-heavy);
    position: relative;

    &:hover {
        cursor: pointer;
    }

    &[aria-selected="true"] {
        background-color: var(--colour-white);

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: auto;
            width: 0;
            height: 0;
        }

        &:before {
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 20px solid var(--colour-alpha);
        }

        &:after {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 18px solid var(--colour-white);
        }
    }
}

/**
   Score Blocks
   ========================================================================== */

.c-report-score {
    display: flex;
    flex-flow: row;
    justify-content: center;

    font-size: 110px;
    font-weight: var(--font-weight-bold);
    line-height: 110px;
    color: var(--colour-alpha);
}

.c-report-score__spacer {
    display: inline-block;
    margin: 0 2px;
    padding: 12px 0;
}

.c-report-score__letter {
    position: relative;

    display: inline-block;
    margin: 0 2px;
    padding: 12px 8px;

    background-color: var(--colour-alpha);

    color: var(--colour-foxtrot-light);

    &:before {
        content: "";

        position: absolute;
        top: 50%;
        left: 0;
        right: 0;

        display: block;
        height: 3px;

        background-color: var(--colour-foxtrot-light);
    }
}

/*
   Pie chart
   ========================================================================== */

.c-report-chart-pie {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.c-report-chart-pie__chart {
    width: 39%;

    canvas {
        border-radius: 100%;
        background-color: var(--colour-foxtrot-dark);
    }
}

.c-report-chart-pie__labels {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 59%;

    dl {
        width: 49%;
        margin: 0 0 20px;
    }

    dd {
        margin: 0;

        + dt {
            margin-top: var(--grid-padding);
        }
    }
}

.c-report-chart-pie__heading {
    display: block;
    margin: 0 0 10px;
    font-size: responsive 18px 20px;
    line-height: 1.2em;
    font-weight: var(--font-weight-bold);
}

.c-report-chart-pie__label {
    display: flex;
    align-items: center;

    &:before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        background-color: var(--colour-alpha);
        border-radius: 100%;
        margin-right: 10px;
        border: 2px solid var(--colour-white);
    }

    &--energy-1 {
        &:before {
            background-color: var(--colour-theme-energy-1);
        }
    }

    &--energy-2 {
        &:before {
            background-color: var(--colour-theme-energy-2);
        }
    }

    &--energy-3 {
        &:before {
            background-color: var(--colour-theme-energy-3);
        }
    }

    &--energy-4 {
        &:before {
            background-color: var(--colour-theme-energy-4);
        }
    }

    &--transport-1 {
        &:before {
            background-color: var(--colour-theme-transport-1);
        }
    }

    &--transport-2 {
        &:before {
            background-color: var(--colour-theme-transport-2);
        }
    }

    &--transport-3 {
        &:before {
            background-color: var(--colour-theme-transport-3);
        }
    }

    &--transport-4 {
        &:before {
            background-color: var(--colour-theme-transport-4);
        }
    }

    &--food-1 {
        &:before {
            background-color: var(--colour-theme-food-1);
        }
    }

    &--other-1 {
        &:before {
            background-color: var(--colour-theme-other-1);
        }
    }

    &--other-2 {
        &:before {
            background-color: var(--colour-theme-other-2);
        }
    }
}

/**
   Tabbed Content
   ========================================================================== */

.c-report-tab-content {
    display: none;

    .no-js &,
    &[aria-hidden="false"] {
        display: block;
    }
}
