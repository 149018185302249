/* ==========================================================================
   Page shapes
   ========================================================================== */

.c-page-shape {
    position: absolute;
}

.c-page-shape-left-top {
    top: 70px;
    left: -127px;
}

.c-page-shape-left-middle {
    top: -130px;
    left: -1020px;
}

.c-page-shape-left-bottom {
    top: 451px;
    left: -320px;
}

.c-page-shape-right-top {
    top: 57px;
    right: -330px;
    z-index: 1;
}

.c-page-shape-right-middle {
    top: -403px;
    right: -740px;
}

.c-page-shape-right-bottom {
    top: 589px;
    right: 50px;
    z-index: 1;
}

.c-page-shape-leaf-top {
    left: calc(50% + 50px);
    top: -184px;
}

/*
   Energy
   ========================================================================== */

.c-page-shape-energy-left {
    left: -118px;
    bottom: -1px;
}

.c-page-shape-energy-right {
    right: -197px;
    bottom: -194px;
}

.c-page-shape-energy-top {
    left: 600px;
    top: -184px;
}

/*
   Transport
   ========================================================================== */

.c-page-shape-transport-left {
    left: -60px;
    bottom: -203px;
}

.c-page-shape-transport-right {
    right: -207px;
    bottom: -106px;
}

/*
   Food
   ========================================================================== */

.c-page-shape-food-left {
    left: -78px;
    bottom: -185px;
}

.c-page-shape-food-right {
    right: -70px;
    bottom: 0;
}

.c-page-shape-food-top {
    left: 600px;
    top: -184px;
}

/*
   Other
   ========================================================================== */

.c-page-shape-other-left {
    left: -140px;
    bottom: -50px;
}

.c-page-shape-other-right {
    right: -115px;
    bottom: -50px;
}

.c-page-shape-other-top {
    left: 600px;
    top: -184px;
}


/*
   Survey
   ========================================================================== */

.c-page-shape-survey-left {
    left: -165px;
    bottom: -162px
}

.c-page-shape-survey-right {
    right: -279px;
    bottom: -263px;
}

.c-page-shape-survey-top {
    left: 600px;
    top: -184px;
}

/*
   Intro
   ========================================================================== */

.c-page-shape-intro-left {
    left: -480px;
    bottom: -800px;
}

.c-page-shape-intro-right {
    right: -154px;
    top: 188px;
}

.c-page-shape-intro-bottom {
    left: -170px;
    bottom: -412px;
}
