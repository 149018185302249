/* ==========================================================================
   Progress
   ========================================================================== */

.c-progress__title {
    margin: 0 auto var(--grid-padding);
    text-align: center;
}

.c-progress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin: 0 auto var(--grid-padding);
    text-align: center;

    .c-progress-step {
        display: block;
        height: 14px;
        width: 14px;
        border-radius: 100%;
        background-color: var(--colour-white);
        z-index: 1;

        &--is-active {
            background-color: var(--colour-black);

            .o-theme-energy & {
                background-color: var(--colour-theme-energy);
            }
            .o-theme-transport & {
                background-color: var(--colour-theme-transport);
            }
            .o-theme-food & {
                background-color: var(--colour-theme-food);
            }
            .o-theme-other & {
                background-color: var(--colour-theme-other);
            }
            .o-theme-survey & {
                background-color: var(--colour-theme-survey);
            }
        }
    }

    progress {
        position: absolute;
        appearance: none;
        width: 100%;
        max-width: 600px;
        height: 20px;
        background-color: var(--colour-white);

        &::-webkit-progress-bar {
            background-color: var(--colour-white);
        }

        &::-moz-progress-bar {
            background-color: var(--colour-white);
        }

        &::-webkit-progress-value {
            background-color: var(--colour-black);

            .o-theme-energy & {
                background-color: var(--colour-theme-energy);
            }
            .o-theme-transport & {
                background-color: var(--colour-theme-transport);
            }
            .o-theme-food & {
                background-color: var(--colour-theme-food);
            }
            .o-theme-other & {
                background-color: var(--colour-theme-other);
            }
            .o-theme-survey & {
                background-color: var(--colour-theme-survey);
            }
        }

        &::-moz-progress-bar {
            background-color: var(--colour-black);

            .o-theme-energy & {
                background-color: var(--colour-theme-energy);
            }
            .o-theme-transport & {
                background-color: var(--colour-theme-transport);
            }
            .o-theme-food & {
                background-color: var(--colour-theme-food);
            }
            .o-theme-other & {
                background-color: var(--colour-theme-other);
            }
            .o-theme-survey & {
                background-color: var(--colour-theme-survey);
            }
        }
    }

    progress[value] {
        height: 2px;
        border: 0;
        background-color: var(--colour-white);
    }

    label {
        display: none;
    }
}
