/* ==========================================================================
   Fonts
   ========================================================================== */

/* Quotes */

$open-quote:            "\201C";
$close-quote:           "\201D";

:root {
   --font-weight-regular:     400;
   --font-weight-medium:      500;
   --font-weight-bold:        700;
   --font-weight-heavy:       900;

   --font-family-alpha:       "effra", sans-serif;
   --font-family-header:      var(--font-family-alpha);
   --font-family-body:        var(--font-family-alpha);

   --font-size-min:           14px;
   --font-size-max:           16px;

   --base-line-height:        24px;
}