/* ==========================================================================
   Links
   ========================================================================== */

a {
    color: var(--colour-grey-dark);
    transition: color .2s ease-out;
    text-decoration: none;

    &:hover {
        color: var(--colour-black);
    }
}

strong {
    a {
        text-decoration: underline;
    }
}