/* ==========================================================================
   Panels
   ========================================================================== */

.c-panel {
    padding: var(--grid-padding);
    background-color: var(--colour-white);
    border-radius: 16px;

    a {
        font-weight: var(--font-weight-bold);
        text-decoration: underline;
    }
}

.c-panel-list {
    margin: 0 0 var(--grid-padding);
    padding: 0;
    list-style: none;

    li {
        padding: 0 0 20px;

        + li {
            padding-top: 20px;
            border-top: 1px solid var(--colour-grey-light);
        }

        p {
            margin: 0 0 20px;
        }
    }
}
