/* ==========================================================================
   Pagination
   ========================================================================== */

.c-pagination {
    display: flex;
    justify-content: center;
}

.c-pagination__list {
    margin: var(--grid-padding-sm) auto 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.c-pagination__item {
    margin: 0 var(--grid-padding-sm) var(--grid-padding-sm) 0;

    &--next,
    &--prev {
        @include break($mq-md-max) {
            display: none;
        }
    }
}

.c-pagination__link {
    padding: 6px 16px;
    display: inline-block;
    font-weight: var(--font-weight-medium);
    font-size: responsive 16px 20px;
    text-decoration: none;
    text-align: center;
    border: 2px solid var(--colour-alpha);
    color: var(--colour-white);
    background: var(--colour-alpha);
    border-radius: var(--border-radius);
    transition: all var(--transition-duration) var(--transition-easing);
    @include font-smoothing;

    &:hover,
    &:active {
        background: var(--colour-white);
        color: var(--colour-alpha);
    }

    .c-pagination__item--active & {
        background: var(--colour-white);
        color: var(--colour-alpha);

        &:hover,
        &:active {
            background: var(--colour-white);
            color: var(--colour-alpha);
        }
    }
}
