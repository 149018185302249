/* ==========================================================================
   Colours
   ========================================================================== */

/*
 * colour naming convention
 *
 * colour-grey-x-light
 * colour-grey-light
 * colour-grey-mid-light
 * colour-grey
 * colour-grey-mid-dark
 * colour-grey-dark
 * colour-grey-x-dark
 */

:root {
    --colour-white:             #fff;
    --colour-black:             #000;
    --colour-header:            #ea5a0b;

    --colour-grey-dark:         #1f201d;
    --colour-grey-mid-dark:     #616161;
    --colour-grey:              #7d7d7d;
    --colour-grey-mid-light:    #b0b0b0;
    --colour-grey-light:        #d8d8d8;

    --colour-alpha-dark:        #862f00;
    --colour-alpha:             #e34200;
    --colour-alpha-light:       #eaaf8b;

    --colour-bravo-x-light:     #bcdbd5;
    --colour-bravo-light:       #91cac7;
    --colour-bravo-mid-light:   #5dbab3;
    --colour-bravo:             #009a92;
    --colour-bravo-dark:        #005c57;

    --colour-charlie-light:     #f7ecb0;
    --colour-charlie:           #ebe103;
    --colour-charlie-dark:      #bcb402;

    --colour-delta-light:       #bce6e6;
    --colour-delta-mid-light:   #5ed6e2;
    --colour-delta:             #03c7dd;
    --colour-delta-mid-dark:    #00707c;
    --colour-delta-dark:        #0379b3;

    --colour-echo-light:        #f4e3eb;
    --colour-echo-mid-light:    #eec2d1;
    --colour-echo:              #dfbcee;
    --colour-echo-mid-dark:     #da7daa;
    --colour-echo-dark:         #eb609f;
    --colour-echo-x-dark:       #c53983;
    --colour-echo-xx-dark:      #840146;

    --colour-foxtrot-light:     #fbf0eb;
    --colour-foxtrot:           #fde2d6;
    --colour-foxtrot-dark:      #dfcdbd;
    --colour-foxtrot-x-dark:    #a5978b;

    --colour-golf-x-light:      #e5e8b4;
    --colour-golf-light:        #c4db61;
    --colour-golf-mid-light:    #a4d011;
    --colour-golf:              #24ad69;
    --colour-golf-dark:         #658300;

    --colour-hotel-light:       #fae9c2;
    --colour-hotel-mid-light:   #f5d385;
    --colour-hotel:             #f2be4a;
    --colour-hotel-dark:        #b98000;


    --colour-theme-energy-light:    var(--colour-golf-x-light);
    --colour-theme-energy:          var(--colour-golf);
    --colour-theme-energy-dark:     var(--colour-golf-dark);
    --colour-theme-energy-1:        var(--colour-golf-x-light);
    --colour-theme-energy-2:        var(--colour-golf-light);
    --colour-theme-energy-3:        var(--colour-golf);
    --colour-theme-energy-4:        var(--colour-golf-dark);

    --colour-theme-transport-light: var(--colour-echo-mid-light);
    --colour-theme-transport:       var(--colour-echo-x-dark);
    --colour-theme-transport-dark:  var(--colour-echo-xx-dark);
    --colour-theme-transport-1:     var(--colour-echo-mid-light);
    --colour-theme-transport-2:     var(--colour-echo-mid-dark);
    --colour-theme-transport-3:     var(--colour-echo-x-dark);
    --colour-theme-transport-4:     var(--colour-echo-xx-dark);

    --colour-theme-food-light:      var(--colour-delta-light);
    --colour-theme-food:            var(--colour-delta);
    --colour-theme-food-dark:       var(--colour-delta-mid-dark);
    --colour-theme-food-1:          var(--colour-delta);

    --colour-theme-other-light:     var(--colour-hotel-light);
    --colour-theme-other:           var(--colour-hotel);
    --colour-theme-other-dark:      var(--colour-hotel-dark);
    --colour-theme-other-1:         var(--colour-hotel-light);
    --colour-theme-other-2:         var(--colour-hotel-mid-light);

    --colour-theme-survey-light:    var(--colour-golf-x-light);
    --colour-theme-survey:          var(--colour-golf-mid-light);
    --colour-theme-survey-dark:     var(--colour-golf-dark);
}
