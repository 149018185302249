/* ==========================================================================
   Filter
   ========================================================================== */

.c-filter__wrap {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto var(--grid-padding);
    padding: 10px var(--grid-padding-sm);
    background: var(--colour-delta);
    border-radius: var(--border-radius);
    width: 100%;
    max-width: 580px;
    z-index: 1;
}

.c-filter-label {
    margin: 0 20px 0 0;
    font-weight: var(--font-weight-heavy);
    white-space: nowrap;
}

/*
   Filter container
   ========================================================================== */

.c-filter {
    position: relative;
    width: 100%;
}

/*
   Trigger
   ========================================================================== */

.c-filter-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--colour-white);
    padding: 7px 10px;
    border: 0;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: var(--transition-duration) var(--transition-easing);
    
    .no-js & {
        display: none;
    }

    .active & {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.c-filter-selected-value {
    text-align: left;
    pointer-events: none;
}

.c-filter-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid var(--colour-delta-dark);
    transition: transform ease-in-out .3s;
    pointer-events: none;

    .c-filter.active &,
    .no-js & {
        transform: rotate(180deg);
    }
}

/*
   Dropdown
   ========================================================================== */

.c-filter-dropdown {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    width: 100%;
    background-color: var(--colour-white);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    max-height: 300px;
    overflow-y: auto;
    transition: var(--transition-duration) var(--transition-easing);
    transform: scaleY(0);
    transform-origin: top center;
    opacity: 0;
    visibility: hidden;

    .no-js & {
        position: relative;
        max-height: none;
    }
    
    .c-filter.active &,
    .no-js & {
        opacity: 1;
        visibility: visible;
        transform: scaleY(1);
    }
}

.c-filter-item {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;

    a {
        width: 100%;
        padding: 7px 10px;
        cursor: pointer;

        &:hover {
            background: var(--colour-grey-light);
        }

        address {
            font-size: 13px;
            font-style: normal;
        }
    }
}
