/* ==========================================================================
   Mobile nav toggle button
   ========================================================================== */

.c-nav-toggle {
    position: absolute;
    top: 14px;
    right: 0;

    font-size: 12px;
    line-height: 1;
    text-transform: lowercase;
    color: var(--colour-grey);

    @include break($mq-xl-min) {
        display: none;
    }
}

.c-nav-toggle__button {
    position: relative;

    display: block;
    width: 40px;
    height: 36px;
    margin: 0 auto 2px;

    background-color: transparent;
    border: 0;
    cursor: pointer;

    font-size: 0;

    &:hover {
        .c-nav-toggle__burger {
            background: var(--colour-charlie);

            &:before,
            &:after {
                background: var(--colour-charlie);
            }
        }
    }
}

.c-nav-toggle__burger {
    position: absolute;
    top: 17px;

    width: 30px;
    height: 2px;
    margin-left: -15px;

    transition: background var(--transition-duration) var(--transition-easing);
    background: var(--colour-bravo);

    &:before,
    &:after {
        content: "";

        position: absolute;
        left: 0;

        display: block;
        width: 100%;
        height: 2px;

        transition: all var(--transition-duration) var(--transition-easing);
        background: var(--colour-bravo);
    }

    &:before {
        transform: translateY(-8px);
    }

    &:after {
        transform: translateY(8px);
    }
}

/* Open */

.js-nav-open {

    .c-nav-toggle__burger {
        background: transparent !important;

        &:before {
            transform: rotate(45deg);
            background: var(--colour-charlie);
        }

        &:after {
            transform: rotate(-45deg);
            background: var(--colour-charlie);
        }
    }
}
