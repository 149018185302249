// sass-lint:disable-all

/* ==========================================================================
   Gravity Forms
   ========================================================================== */

/* Wrapper */

.gform_wrapper {
    width: 100%;
}

.gform_heading {
    margin: 0 0 var(--grid-padding);
}

/*
   Labels
   ========================================================================== */

.gfield_label {
    margin: 0 0 8px;
    display: block;
    line-height: 1.2;
    font-weight: var(--font-weight-medium);
}

.gfield_description {
    margin: 0 0 8px;
    display: block;
    font-size: 14px;
    line-height: 1.2;
    color: var(--colour-grey)
}

/*
   Fields
   ========================================================================== */

.gform_fields {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gfield {
    margin-bottom: var(--base-line-height);
    width: 100%;

    input,
    textarea {
        width: 100%;
    }
}

.gfield_50 {
    width: 100%;

    @include break($mq-md-min) {
        width: 48%;
    }
}

/*
   Checkboxes / Radio buttons
   ========================================================================== */

.gfield_checkbox,
.gfield_radio {
    margin: 0 0 -10px;
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .gfield_columns_2 & {
        display: flex;
        flex-wrap: wrap;

        li {
            @include break($mq-md-min) {
                width: 50%;
            }
        }
    }

    .gfield_inline & {
        display: flex;
        flex-wrap: wrap;

        li {
            @include break-between($screen-md-min, $screen-xl-max) {
                width: 32%;
            }

            @include break($mq-xxl-min) {
                width: 16%;
            }
        }
    }
}

/*
   Buttons
   ========================================================================== */

.gform_footer {
    text-align: center;
}

.gform_button {
    margin: 0 auto;
    display: inline-block;
    font-weight: var(--font-weight-bold);
    font-size: responsive 16px 20px;
    text-decoration: none;
    text-align: center;
    color: var(--colour-white);
    background: var(--colour-alpha);
    box-shadow: 3px 3px 0 rgba(0, 0, 0, .2);
    border-radius: var(--border-radius);
    transition: all var(--transition-duration) var(--transition-easing);
    @include font-smoothing;
    border: none;

    @include break($mq-xxs-max) {
        display: block;
        width: 100%;
        margin: 0 auto;
    }

    @include break($mq-md-max) {
        padding: 10px 20px;
    }

    &:hover,
    &:active {
        cursor: pointer;
        box-shadow: none;
        transform: translate(3px, 3px);
        color: var(--colour-white);
    }
}

/*
   Required
   ========================================================================== */

.gfield_required {
    color: var(--colour-bravo);
}

/*
   Validation
   ========================================================================== */

.validation_error {
    @include font-smoothing;
    margin-bottom: var(--base-line-height);
    padding: var(--base-line-height) 4.48%;
    color: var(--colour-white);
    background: var(--colour-bravo);
}
.validation_message {
    display: block;
    margin-top: 4px;
    color: var(--colour-bravo);
}
.gfield_error .ginput_container {
    border-left: 4px solid var(--colour-bravo);
}

/*
   Honeypot
   ========================================================================== */

.gform_validation_container {
    @include hide-content;
}

// sass-lint:enable-all
