/* ==========================================================================
   Generic Text Styling
   ========================================================================== */

/*
  Basic Text
  ========================================================================== */

.o-text-centre {
    text-align: center;
}

.o-text-bravo {
    color: var(--colour-bravo);
}

.o-screen-reader {
    display: none;
}


/*
  Page container
  ========================================================================== */

.mce-content-body,
.o-content-editor {

    p {
        line-height: 1.6;
    }

    a {
        text-decoration: underline;
        color: var(--colour-bravo);

        &:focus,
        &:hover {
            color: var(--colour-alpha);
        }
    }

    ul {
        margin: 0 0 var(--base-line-height);
        padding: 0;

        li {
            position: relative;
            padding-left: 20px;

            &:before {
                content: "";
                display: block;
                height: 8px;
                width: 8px;
                background: var(--colour-echo-x-dark);
                border-radius: 100%;
                position: absolute;
                top: 9px;
                left: 0;
            }

            + li {
                margin-top: var(--grid-padding-sm);
            }
        }
    }
}


/*
  OEmedded videos
  ========================================================================== */

.o-post-embed {
    position: relative;

    &--video {
        padding-bottom: 56.25%;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
