/* ==========================================================================
   Buttons
   ========================================================================== */

.c-button {
    display: inline-block;
    padding: 10px 12px;
    transition: all var(--transition-duration) var(--transition-easing);
    background-color: var(--colour-alpha);
    color: var(--colour-white) !important;
    border: 1px solid var(--colour-alpha);
    border-radius: 100px;
    cursor: pointer;
    @include font-smoothing;
    font-size: responsive 14px 18px;
    font-weight: var(--font-weight-bold);
    line-height: 18px;
    text-decoration: none !important;
    text-align: center;

    @include break($mq-lg-min) {
        padding: 12px 15px;
    }

    @include break($mq-xl-min) {
        padding: 12px 22px;
    }

    &:focus,
    &:hover {
        background-color: var(--colour-alpha-dark);
        border-color: var(--colour-alpha-dark);
        color: var(--colour-white) !important;
        transform: translateY(-1px);
    }

    &:active {
        background-color: var(--colour-alpha);
        border-color: var(--colour-alpha);
        color: var(--colour-white) !important;
    }

    + .c-button {
        margin-left: 10px;
    }
}

.c-button--link{
    color: var(--colour-alpha);
    text-decoration: underline;

    &:hover,
    &:focus{
        text-decoration: none;
    }
}


.c-button--bravo {
    background-color: var(--colour-bravo);
    border-color: var(--colour-bravo);
    color: var(--colour-white) !important;

    &:focus,
    &:hover {
        background-color: var(--colour-bravo-dark);
        border-color: var(--colour-bravo-dark);
        color: var(--colour-white) !important;
    }

    &:active {
        background: var(--colour-bravo);
        border-color: var(--colour-bravo);
        color: var(--colour-white) !important;
    }
}

.c-button--charlie {
    background-color: var(--colour-charlie);
    border-color: var(--colour-charlie);
    color: var(--colour-black) !important;

    &:focus,
    &:hover {
        background-color: var(--colour-charlie-dark);
        border-color: var(--colour-charlie-dark);
        color: var(--colour-black) !important;
    }

    &:active {
        background: var(--colour-charlie);
        border-color: var(--colour-charlie);
        color: var(--colour-black) !important;
    }
}

.c-button--foxtrot {
    background-color: var(--colour-foxtrot);
    border-color: var(--colour-foxtrot);
    color: var(--colour-black) !important;

    &:focus,
    &:hover {
        background-color: var(--colour-foxtrot-dark);
        border-color: var(--colour-foxtrot-dark);
        color: var(--colour-black) !important;
    }

    &:active {
        background: var(--colour-foxtrot);
        border-color: var(--colour-foxtrot);
        color: var(--colour-black) !important;
    }
}
