/* ==========================================================================
   Flash Alerts
   ========================================================================== */

.alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: var(--grid-padding-sm) 0;
    padding: 7px 12px;
    border-left: 5px solid var(--colour-grey);
    background-color: var(--colour-white);
    font-weight: var(--font-weight-bold);
}

.alert-close {
    position: relative;
    float: right;

    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;

    border: 1px solid var(--colour-grey-mid-dark);
    border-radius: 5px;
    background-color: transparent;

    &:before,
    &:after {
        content: "";

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);

        display: block;
        width: 12px;
        height: 2px;

        transition: background-color var(--transition-duration) var(--transition-easing);
        background-color: var(--colour-black);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
}


.alert-success {
    border-left: 5px solid var(--colour-bravo);

    .alert-close {
        &:focus,
        &:hover {
            &:before,
            &:after {
                background-color: var(--colour-bravo);
            }
        }
    }
}

.alert-info {
    border-left: 5px solid var(--colour-delta-dark);

    .alert-close {
        &:focus,
        &:hover {
            &:before,
            &:after {
                background-color: var(--colour-delta-dark);
            }
        }
    }
}

.alert-warning {
    border-left: 5px solid var(--colour-echo-x-dark);

    .alert-close {
        &:focus,
        &:hover {
            &:before,
            &:after {
                background-color: var(--colour-echo-x-dark);
            }
        }
    }
}

.alert-danger {
    border-left: 5px solid var(--colour-alpha-dark);
    background-color: var(--colour-alpha);
    color: var(--colour-white);

    .alert-close {
        border-color: var(--colour-white);

        &:before,
        &:after {
            background-color: var(--colour-white);
        }

        &:focus,
        &:hover {
            &:before,
            &:after {
                background-color: var(--colour-alpha-light);
            }
        }
    }
}
