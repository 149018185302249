/* ==========================================================================
   Cards
   ========================================================================== */

.c-cards__wrap {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;

    @include break($mq-md-min) {
        flex-flow: row wrap;
    }
}

.c-cards-title {
    text-align: center;
    font-size: responsive 20px 32px;
}

.c-cards-subtitle {
    font-size: 16px;
}

/*
   Individual cards
   ========================================================================== */

.c-cards__item {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex: 1;
    margin: 0 var(--grid-padding);

    background: var(--colour-grey-x-light);
    border-radius: var(--border-radius);
    border: 1px solid var(--colour-grey-light);
    box-shadow: 0 2px 40px 0 rgba(221, 221, 221, .5);

    @include break-between($screen-sm-min, $screen-md-max) {
        width: 50%;
    }

    &:only-of-type {
        margin-left: auto;
        margin-right: auto;
        max-width: var(--grid-width-sm);
    }

    &__body {
        padding: var(--grid-padding);

        p {
            margin-bottom: 0;
        }
    }

    &__footer {
        border-top: 1px solid var(--colour-grey-light);
        padding: var(--grid-padding);
        background: var(--colour-white);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__image {
        /*background: $colour-alpha url("../../assets/svg/preload.svg") no-repeat center;*/
        backface-visibility: hidden;
        overflow: hidden;
        @include object-fit-parent (340px, 100%);

        img {
            overflow: hidden;
            border-radius: var(--border-radius) var(--border-radius) 0 0;
            transition: var(--transition-duration) var(--transition-easing);
            @include object-fit-image;

            &:hover {
                background: var(--colour-alpha);
                transform: scale(1.025);
            }
        }
    }
}
