/* ==========================================================================
   Tooltips
   ========================================================================== */

.c-tooltip {
    position: absolute;
    top: -20px;
    right: -20px;
    background-color: var(--colour-echo);
    max-width: 180px;
    padding: 25px;
    text-align: center;
    // mask: url("../../../assets/svg/tooltip.svg");
    mask-border: url("../../../assets/svg/tooltip.svg");

    @include break($mq-lg-min) {
        max-width: 280px;
    }

    h2 {
        margin: 0;

        + p {
            margin-top: 8px;
        }
    }

    p {
        margin: 0;
        font-size: responsive 13px 15px;
        line-height: 1.3;
    }

    &--echo {
        background-color: var(--colour-echo);
    }

    &--echo-dark {
        padding: 40px;
        background-color: var(--colour-echo-dark);
    }
}
