/* ==========================================================================
   Hero
   ========================================================================== */

.c-hero {
    position: relative;
    @include break($mq-xs-max) {
        margin-bottom: var(--base-line-height);
        height: calc(100vh - 137px); /* height of the navbar */
    }
    @include break-between($screen-sm-min, $screen-lg-max) {
        height: 400px;
    }
    @include break-between($screen-xl-min, $screen-xl-max) {
        height: 510px;
    }
    @include break($mq-xxl-min) {
        height: 660px;
    }
}

/*
   Image
   ========================================================================== */

.c-hero__image {
    @include object-fit-parent(100%, 100%);
    @include overlay-flat(5);
    z-index: 0;
    /*background: $colour-alpha url("../../assets/svg/preload.svg") no-repeat center; */

    img {
        @include object-fit-image;
    }
}

/*
   Content
   ========================================================================== */

.c-hero__content {
    @include center-both;
    z-index: 10;
}

/*
   Title
   ========================================================================== */

.c-hero__title {
    color: var(--colour-white);
    font-size: responsive 20px 52px;
}

.c-hero__subtitle {
    color: var(--colour-alpha);
    font-size: responsive 18px 26px;
}
