// sass-lint:disable-all

/* ==========================================================================
   Lazysizes
   ========================================================================== */

.lazyload,
.lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    transition: opacity .3s;
}

// sass-lint:enable-all
