/* ==========================================================================
   Headings
   ========================================================================== */


.o-h1 {
    @include font-smoothing;
    @include header-style;
    line-height: 1.1;
    font-size: 40px;
    font-weight: var(--font-weight-heavy);
}

.o-h2 {
    @include font-smoothing;
    @include header-style;
    line-height: 1.2;
    font-size: 36px;
    font-weight: var(--font-weight-heavy);
}

.o-h3 {
    @include font-smoothing;
    @include header-style;
    line-height: 1.2;
    font-size: 30px;
    font-weight: var(--font-weight-heavy);
}

.o-h4 {
    @include font-smoothing;
    @include header-style;
    line-height: 1.3;
    font-size: 24px;
    font-weight: var(--font-weight-heavy);
}

.o-h5 {
    @include font-smoothing;
    @include header-style;
    line-height: 1.3;
    font-size: 20px;
    font-weight: var(--font-weight-bold);
}

.o-h6 {
    @include font-smoothing;
    @include header-style;
    line-height: 1.3;
}
