/* ==========================================================================
   Form
   ========================================================================== */

.c-form__wrap {
    border: 0;
    width: 100%;
    max-width: 100%;

    fieldset {
        margin: 0;
        padding: 0;
        border: 0;
    }

    p {
        margin: 0 0 var(--grid-padding-sm);
        font-size: 14px;
        text-align: center;

        a {
            font-weight: var(--font-weight-bold);
            text-decoration: underline;
        }
    }

    .radio {
        label {
            display: flex;
            align-items: center;
        }

        input[type="radio"] {
            margin-right: 8px;
        }

        + .radio {
            margin-left: var(--grid-padding-sm);
        }

        &.is-disabled {
            opacity: .3;
        }
    }

    .list-unstyled {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            line-height: 1.4;
        }
    }

    .btn-primary,
    .btn-default {
        @extend .c-button;
    }
}

/*
   Errors / Help
   ========================================================================== */

.c-form-error {
    margin: 0 auto var(--grid-padding);
}

.c-form__help {
    p {
        font-size: 14px;
    }

    ul {
        li {
            font-size: 14px;

            &:before {
                top: 8px;
            }
        }
    }
}

/*
   Headings
   ========================================================================== */

.c-form__heading {
    margin: 0 auto var(--grid-padding);
    text-align: center;
}

/*
   Form groups & elements
   ========================================================================== */

.form-group__wrap {
    + .form-group__wrap {
        margin-top: 20px;
    }
}

.form-user-code {
    display: flex;
    flex-flow: row;

    .form-group {
        flex: 1;
        margin: 0 5px !important;

        .form-control {
            -moz-appearance: textfield;

            font-size: 26px;
            font-weight: var(--font-weight-bold);
            text-align: center;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &::placeholder {
                font-weight: var(--font-weight-regular);
            }
        }
    }
}

.form-group {
    label {
        display: block;
        font-weight: var(--font-weight-bold);
        font-size: 15px;

        &.checkbox-label {
            display: flex;
            align-items: center;
        }
    }



    .control-label {
        &.required {
            &:after {
                content: " (required)";

                .c-form-dashboard & {
                    color: var(--colour-charlie);
                }
            }
        }
    }
}

.c-form-radio__wrap {
    float: left;
    border-radius: 50%;
    margin-right: 10px;
}

/*
   Input group
   ========================================================================== */

.input-group {
    display: flex;
    align-self: flex-start;

    input[type="text"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    input[type="email"],
    input[type="date"],
    input[type="url"] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        + .input-group-addon {
            border-radius: 0 var(--border-radius) var(--border-radius) 0;
            border-left: 0;
            border-right: 1px solid var(--colour-echo-x-dark);
        }
    }
}

.input-group-addon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    transition: border var(--transition-duration) var(--transition-easing);
    background-color: var(--colour-grey-light);
    border: 1px solid var(--colour-black);
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    border-right: 0;

    @include break($mq-lg-min) {
        padding: 0 40px;
    }

    + input[type="text"],
    + input[type="password"],
    + input[type="tel"],
    + input[type="number"],
    + input[type="email"],
    + input[type="date"],
    + input[type="url"] {
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }

    .is-disabled & {
        border-color: var(--colour-grey-dark);
    }
}

.input-group-percent {
    display: inline-flex;
    align-items: flex-end;
}

.input-group-percent__percent {
    display: block;
    padding: 0 0 8px 5px;
    font-weight: var(--font-weight-heavy);
}

/*
   Form navigation
   ========================================================================== */

.c-form-navigation {
    margin: var(--grid-padding-md) auto 0;
    padding: var(--grid-padding-sm);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    background-color: var(--colour-white);
    border-radius: 64px;

    @include break($mq-lg-min) {
        padding: 20px;
    }

    .form-group {
        .checkbox {
            padding: 0 10px;

            label {
                display: flex;
                align-items: center;
                line-height: 1.2;
                font-size: responsive 13px 15px;
            }
        }

    }
}

/*
   Form buttons
   ========================================================================== */

.c-form-button__label {
    display: block;
    margin: 0 8px 0 0;
}

.c-form-button__label--inner {
    display: none;
}

.c-form-button--add {
    position: relative;
    border: 0;
    border-radius: var(--border-radius);
    transition: all var(--transition-duration) var(--transition-easing);
    height: 50px;
    width: 50px;
    background-color: var(--colour-grey-light);

    &:focus,
    &:hover {
        cursor: pointer;
        background-color: var(--colour-grey-mid-light);
        transform: translateY(-1px);
    }

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 20px;
        height: 4px;
        background-color: var(--colour-black);
    }

    &:after {
        transform: rotate(90deg);
    }

    &:disabled {
        cursor: not-allowed !important;
        background-color: var(--colour-grey-light);
    }
}

.grecaptcha-badge {
    z-index: 99999;
}
