/* ==========================================================================
   Mixins
   ========================================================================== */

/*
   Clearfix
   ========================================================================== */

@mixin clearfix {
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}


/*
   Media Query Breakpoints
   ========================================================================== */

@mixin break($query) {
    @media ($query) {
        @content
    }
}

@mixin break-min($min) {
    @media (min-width: $min) {
        @content
    }
}

@mixin break-max($max) {
    @media (max-width: $max) {
        @content
    }
}

@mixin break-between($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content
    }
}

/*
   Image overlay
   ========================================================================== */

@mixin overlay-flat($z-index) {
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z-index;
    }
}

/*
   Object fit polyfill
   ========================================================================== */

@mixin object-fit($attr) {
    object-fit: $attr;
    font-family: "object-fit: #{$attr}";
}

/*
   Object fit parent
   ========================================================================== */

@mixin object-fit-parent($height, $width) {
    height: $height;
    width: $width;
    position: relative;
}

/*
   Object fit image
   ========================================================================== */

@mixin object-fit-image {
    @include object-fit(cover);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
}

/*
   FOUT fix
   ========================================================================== */

@mixin fout-fix {
    .wf-loading & {
        visibility: hidden;
    }
}

/*
   Hide content
   ========================================================================== */

@mixin hide-content {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}


/*
   Font smoothing
   ========================================================================== */

@mixin font-smoothing {
    // sass-lint:disable-all
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // sass-lint:enable-all
}


/*
   Header Styling
   ========================================================================== */

@mixin header-style {
    margin-top: 0;
    margin-bottom: var(--base-line-height);

    font-family: var(--font-family-header);
    font-weight: var(--font-weight-bold);
    color: var(--colour-grey-dark);
}


/*
    No list style
   ========================================================================== */

@mixin list-style-none {
    list-style: none;
    padding-left: 0;
}

/*
   Center both
   ========================================================================== */

@mixin center-both {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/*
   Consistent spacing
   ========================================================================== */

/**
 *
 * This mixin adds padding to an element using consistent base values to try
 * and normalize the spacing as the padding reduces towards mobile.
 *
 * Mind blown...
 */

// @mixin padding($side, $modifier) {
//     @media ($mq-xs-max) {
//         padding-#{$side}: round(($margin-sm-base) * $modifier);
//     }
//     @media ($mq-sm-min) and ($mq-xl-max) {
//         padding-#{$side}: round($margin-md-base * $modifier);
//     }
//     @media ($mq-xxl-min) {
//         padding-#{$side}: round($margin-lg-base * $modifier);
//     }
// }

// @mixin margin($side, $modifier) {
//     @media ($mq-xs-max) {
//         margin-#{$side}: round(($margin-sm-base) * $modifier);
//     }
//     @media ($mq-sm-min) and ($mq-xl-max) {
//         margin-#{$side}: round($margin-md-base * $modifier);
//     }
//     @media ($mq-xxl-min) {
//         margin-#{$side}: round($margin-lg-base * $modifier);
//     }
// }

@mixin mobile-gutter {
    @media (max-width: 360px) {
        padding-left: var(--grid-padding-sm);
        padding-right: var(--grid-padding-sm);
    }
    @media (min-width: 361px) {
        padding-left: var(--grid-padding);
        padding-right: var(--grid-padding);
    }
}
