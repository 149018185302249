/* ==========================================================================
   Form - Calculator
   ========================================================================== */

.c-form-calculator {

    &.c-form__wrap {
        height: 100%;
    }

    form {
        display: flex;
        flex-flow: column;
        height: 100%;
    }

    .form-group {
        transition: opacity var(--transition-duration) var(--transition-easing);

        label {
            margin: 0 0 3px;
            align-self: flex-start;

            + .help-block {
                margin-top: 2px;
            }
        }

        &.is-disabled {
            opacity: .3;
        }
    }

    fieldset {
        flex: 1;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }

        .input-group {
            width: 100%;
        }

        .form-group {
            margin: 0 5px;
            flex: 1;
            flex-flow: column;
        }
    }

    table {
        width: auto;
        margin: 0 auto;

        td {
            padding: 5px;

            @include break($mq-lg-min) {
                padding: 5px 10px;
            }
        }

        input[type="text"],
        input[type="password"],
        input[type="tel"],
        input[type="number"],
        input[type="email"],
        input[type="date"],
        input[type="url"] {
            padding: 10px;
            max-width: 80px;
        }
    }

    .c-form-navigation {
        .form-group {
            label {
                margin: 0;
            }

            input[type="radio"],
            input[type="checkbox"] {
                width: 22px;
                min-width: 22px;
                height: 22px;
                min-height: 22px;
            }
        }
    }

    .c-form-repeater {
        display: flex;
        flex-flow: column;
    }

    .c-form-repeater__item {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        width: 100%;

        .form-group {
            label {
                display: none;
            }
        }

        > div {
            display: flex;
            align-items: flex-start;
            margin: 0 0 20px;
            width: calc(100% + 10px);

            &:nth-child(1) {
                .form-group {
                    label {
                        display: block;
                    }
                }
            }
        }
    }

    .c-form-repeater__remove {
        position: relative;

        display: block;
        width: 30px;
        min-width: 30px;
        height: 30px;
        margin: 0 0 0 15px;
        padding: 0;
        align-self: center;

        transition: opacity var(--transition-duration) var(--transition-easing);
        background: transparent;
        border: 0;
        cursor: pointer;

        font-size: 0;

        &:before,
        &:after {
            content: "";

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);

            display: block;
            width: 24px;
            height: 4px;

            transition: background-color var(--transition-duration) var(--transition-easing);
            background-color: var(--colour-black);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:focus,
        &:hover {
            &:before,
            &:after {
                background-color: var(--colour-alpha);
            }
        }

        &:disabled {
            opacity: .3;

            &:before,
            &:after {
                background-color: var(--colour-black);
            }
        }
    }

    .c-form-radio__wrap {
        border: 2px solid var(--colour-black);
    }

    .c-form-button__wrap {
        display: flex;
        flex-flow: row !important;
        justify-content: flex-end;
        align-items: center !important;
        width: 100% !important;
    }
}
