/* ==========================================================================
   Dashboard
   ========================================================================== */

.c-dashboard {
    position: relative;
    display: flex;
    flex-flow: column;
    z-index: 2;
    margin: 0 auto;
    padding-top: var(--grid-padding-sm);
    padding-bottom: var(--grid-padding);
}

/*
   Header
   ========================================================================== */

.c-dashboard-header {
    margin: 0 0 var(--grid-padding);
    text-align: center;
}

.c-dashboard-header__logo {
    svg {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 330px;
        height: 100%;
        max-height: 150px;

        path {
            fill: var(--colour-alpha);
        }
    }
}

/*
   Headings
   ========================================================================== */

.c-dashboard__heading {
    margin: 0;

    + .c-dashboard__subheading {
        margin-top: 5px;
    }

    + .c-dashboard__caption {
        margin-top: var(--grid-padding);
    }

    + .c-dashboard__button {
        margin-top: var(--grid-padding-md);
    }
}

.c-dashboard__subheading {
    margin: 0;
    font-weight: var(--font-weight-normal);

    + .c-button {
        margin-top: 20px;
    }
}

.c-dashboard__caption {
    margin: 0;

    + .c-dashboard__button {
        margin-top: var(--grid-padding);
    }
}

/*
   Grid
   ========================================================================== */

.c-dashboard-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--grid-padding);
    margin: 0 auto var(--grid-padding-md);
    padding: 0;
    max-width: 600px;
    list-style: none;
}


.c-dashboard-grid__item {
    text-align: center;

    svg {
        transition: var(--transition-duration) var(--transition-easing);
        max-width: 100%;
    }
}

.c-dashboard-grid__link {
    display: block;

    &:hover {
        text-decoration: underline;

        svg {
            transform: translateY(2px);
        }
    }
}

.c-dashboard-grid--school{

    display: flex;
    flex-wrap: wrap;
    grid-gap: 0;
    justify-content: center;

    .c-dashboard-grid__item{
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        position: relative;
        width: 100%;
        padding: 0 10px;
        display: flex;
    }

}

.c-dashboard-grid__label {
    display: block;
    margin: 0;
    line-height: 1.3;
}

.c-dashboard__button {
    margin: var(--grid-padding) auto 0;
}

/*
   Table
   ========================================================================== */

.c-dashboard-table {
    margin: 0 -18px var(--grid-padding-md);
    text-align: left;

    th,
    td {
        padding: 18px;
        font-size: 20px;
        font-weight: var(--font-weight-bold);

        a {
            color: var(--colour-alpha);
            text-decoration: underline;
        }
    }

    th {
        font-size: 16px;
    }
}

/*
   Content
   ========================================================================== */

.c-dashboard-content {
    margin: var(--grid-padding-md) auto 0;
}
