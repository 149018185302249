/* ==========================================================================
   Mobile menu
   ========================================================================== */

.c-nav-mobile {
    position: fixed;
    top: 0;
    right: -var(--mobile-nav-width);

    width: var(--mobile-nav-width);
    height: 100%;
    z-index: 200;
    background: var(--colour-white);
    overflow: auto;
    transition: all var(--transition-duration) var(--transition-easing);
    visibility: hidden;
    opacity: 0;

    /* sass-lint:disable-all */
    -webkit-overflow-scrolling: touch;
    /* sass-lint:enable-all */

    &.c-nav-mobile--nav-open {
        right: 0;
        visibility: visible;
        opacity: 1;
    }
}

/*
   List
   ========================================================================== */

.c-nav-mobile__list {
    list-style: none;
    margin: var(--base-line-height) var(--grid-padding);
    padding-left: 0;
    text-align: left;
}

.c-nav-mobile__list-item {
    display: block;
    border-bottom: 1px solid var(--colour-grey-mid-light);

    &:last-of-type {
        border-bottom: 0;
    }
}

.c-nav-mobile__list-link {
    display: block;
    text-align: center;
    padding: var(--grid-padding-sm) var(--grid-padding);
    color: var(--colour-alpha);
    font-size: 15px;
    transition: all var(--transition-duration) var(--transition-easing);
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        color: var(--colour-white);
        text-decoration: none;
        background-color: var(--colour-alpha);
    }
}
