/* ==========================================================================
   Help block
   ========================================================================== */

.help-block {
    display: block;
    font-size: 15px;
    line-height: 1.2;

    /* Help block above an input */
    + .input-group {
        margin: 7px 0 0;
    }
}

/* Help block under an input */
input,
.input-group {
    + .help-block {
        margin: 10px 0 0;
    }
}

/* Help block under a label */
label {
    + .help-block {
        margin: -7px 0 10px;
    }
}

.c-form-dashboard {
    .help-block {
        color: var(--colour-white);
    }
}

.c-form-dashboard,
.c-form-calculator {
    .help-block {
        .list-unstyled {
            li {
                display: flex;
                align-items: center;

                .glyphicon.glyphicon-exclamation-sign {
                    position: relative;
                    display: block;
                    background-color: var(--colour-alpha);
                    width: 20px;
                    height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                    border-radius: 100px;
                    margin: 0 10px 0 0;

                    &:before,
                    &:after {
                        content: "";
                        display: block;
                        width: 2px;
                        background-color: var(--colour-white);
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        border-radius: 100px;
                    }

                    &:before {
                        height: 8px;
                        top: 4px;
                    }

                    &:after {
                        height: 2px;
                        bottom: 4px;
                    }
                }
            }
        }
    }
}
