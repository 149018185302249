/* ==========================================================================
   Form - Dashboard
   ========================================================================== */

.c-form-dashboard {
    margin: 0 auto;
    padding: var(--grid-padding);
    background-color: var(--colour-bravo);
    border-radius: 16px;
    max-width: 550px;

    .c-form__heading,
    p {
        color: var(--colour-white);

        a {
            color: var(--colour-white);
        }
    }

    .form-group {
        clear: both;
        overflow: hidden;

        + .form-group {
            margin-top: 20px;
        }

        + input[type="hidden"] {
            + .form-group {
                margin-top: 20px;
            }
        }

        label {
            margin: 0 0 10px;
            color: var(--colour-white);
        }

        .radio {
            float: left;

            label {
                display: flex;
                align-items: center;
            }

            + .radio {
                margin-left: var(--grid-padding-lg);
            }
        }
    }

    .btn-primary,
    .btn-default {
        @extend .c-button;
        @extend .c-button--charlie;
        width: 100%;
    }
}
