/* ==========================================================================
   Body
   ========================================================================== */

body {
    @include font-smoothing;
    font-family: var(--font-family-body);
    font-size: var(--font-size-max);
    line-height: 1.5;
    font-weight: var(--font-weight-regular);
    color: var(--colour-grey-dark);
    background-color: var(--colour-foxtrot-light);
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    padding-bottom: 40px;

    @include break($mq-lg-max){
        overflow: hidden;
        max-height: 100vh;

        &:after {
            content: "";
            display: block;
            background: var(--colour-bravo);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
        }

        &:before{
            content: 'To access Count Your Carbon, please visit us using a tablet, laptop or desktop computer.';
            position: absolute;
            top: 100px;
            left: 0;
            height: 400px;

            padding: 0 30px;
            z-index: 99999999;
            color: var(--colour-white);
            font-size: 20px;
            text-align: center;
        }
    }

    @media ($mq-sm-min) and (orientation: portrait) and ($mq-lg-max){

        &:after {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='119' height='118' viewBox='0 0 119 118'%3E%3Cg fill='%23FFF' fill-rule='evenodd'%3E%3Cpath fill-opacity='.5' d='M5 82.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zM91 108v4H13V52l31-.185V46H5.26C2.36 46 0 48.355 0 51.251v61.495A5.263 5.263 0 0 0 5.26 118h94.48c2.9 0 5.26-2.357 5.26-5.254V108H91z'/%3E%3Cpath d='M53 13h60v78H53V13zm29-6.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zM86 98a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm27.747-98H52.254A5.263 5.263 0 0 0 47 5.26v94.48c0 2.9 2.357 5.26 5.254 5.26h61.493A5.262 5.262 0 0 0 119 99.74V5.26c0-2.9-2.357-5.26-5.253-5.26zm-75.05 11.801l-2.493.23a26.54 26.54 0 0 0-22.908 22.894l4.444-3.568a.942.942 0 0 1 1.185 1.464l-6.16 5.012a.747.747 0 0 1-1.087-.159l-4.516-6.608a.932.932 0 1 1 1.548-1.038l2.786 4.223a28.38 28.38 0 0 1 8.026-15.998 28.417 28.417 0 0 1 16.008-8.02L38.35 10c.687.469.873 1.156.348 1.801'/%3E%3C/g%3E%3C/svg%3E") no-repeat 50% var(--colour-bravo);
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
        }

        &::before{
            content: unset;
        }

    }

    &.mce-content-body {
        margin: 20px;
    }

    &.o-theme-question {

        &.o-theme-energy {
            background-color: var(--colour-theme-energy-light);
        }

        &.o-theme-transport {
            background-color: var(--colour-theme-transport-light);
        }

        &.o-theme-food {
            background-color: var(--colour-theme-food-light);
        }

        &.o-theme-other {
            background-color: var(--colour-theme-other-light);
        }

        &.o-theme-survey {
            background-color: var(--colour-theme-survey-light);
        }

        &.o-theme-energy,
        &.o-theme-transport,
        &.o-theme-food,
        &.o-theme-other,
        &.o-theme-survey {
            &:before {
                content: "";
                display: block;
                background-image: url("../../../assets/svg/cutout.svg");
                background-repeat: no-repeat;
                background-size: var(--grid-width-lg);
                background-position: top center;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                @include break($mq-xxl-min) {
                    background-size: 100%;
                }
            }
        }
    }
}
