/* ==========================================================================
   Reset
   ========================================================================== */

* {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;

    @include break($mq-sm-max) {
        &.js-nav-open {
            overflow-y: hidden;
        }
    }
}

img {
    max-width: 100%;
    width: auto;
    height: auto;
}

figure {
    margin: 0;
}

table {
    width: 100%;
    border-collapse: collapse;
}

p,
pre,
label {
    margin-top: 0;
    margin-bottom: var(--base-line-height);
}

strong,
b {
    font-weight: var(--font-weight-bold);
}

ol,
ul {
    list-style-position: outside;
    margin-top: 0;
    margin-bottom: var(--base-line-height);
    padding-left: 20px;
}

hr {
    border: 0;
    border-top: 1px solid var(--colour-grey);
    margin: 0 0 (#{var(--base-line-height)} * 2);
}
