/* ==========================================================================
   Wrapper
   ========================================================================== */

.o-wrapper {
    max-width: var(--grid-width-lg);

    @include break($mq-md-min) {
        margin: 0 auto;
    }
}

.o-wrapper--small {
    max-width: var(--grid-width-sm);
}

.o-wrapper--medium {
    max-width: var(--grid-width-md);
}

.o-wrapper-parent {
    @include mobile-gutter;
}

.o-wrapper-centre {
    width: 100%;
    max-width: var(--grid-width-xl);
    margin: 0 auto;
}
