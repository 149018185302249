/* ==========================================================================
   Grid
   ========================================================================== */

$margin-sm-base:        (var(--base-line-height) / 2);
$margin-md-base:        2.189vw;
$margin-lg-base:        var(--base-line-height);

:root {
   --grid-padding-xl:      72px;
   --grid-padding-lg:      64px;
   --grid-padding-md:      48px;
   --grid-padding:         32px;
   --grid-padding-sm:      16px;
   --grid-padding-xs:      8px;

   --grid-width-xs:        480px;
   --grid-width-sm:        720px;
   --grid-width-md:        1200px;
   --grid-width-lg:        1366px;
   --grid-width-xl:        1872px;

   --border-radius:        6px;

   --mobile-nav-width:     82.4vw;

   --transition-duration:  .2s;
   --transition-easing:    cubic-bezier(.4, 0, .2, 1);
}