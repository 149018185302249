/* ==========================================================================
   Calculator
   ========================================================================== */

.c-calculator {
    margin: 0 0  var(--grid-padding);
    position: relative;
}

/*
   Header
   ========================================================================== */

.c-calculator-header {
    margin: 0 0 var(--grid-padding);
    text-align: center;
}

/*
   Content
   ========================================================================== */

.c-calculator-content{
    max-width: 750px;
    margin: 0 auto;
}

/*
   Buttons
   ========================================================================== */

.c-calculator-buttons{

    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;

}

/*
   Headings
   ========================================================================== */

.c-calculator__heading {
    margin: 0 auto var(--grid-padding);
    padding: 10px var(--grid-padding-md);
    border-radius: 32px;
    border: 2px solid var(--colour-white);
    line-height: 1.2;
    text-align: center;
    width: fit-content;

    .o-theme-energy & {
        background-color: var(--colour-theme-energy);
        color: var(--colour-white);
    }

    .o-theme-transport & {
        background-color: var(--colour-theme-transport);
        color: var(--colour-white);
    }

    .o-theme-food & {
        background-color: var(--colour-theme-food);
    }

    .o-theme-other & {
        background-color: var(--colour-theme-other);
    }

    .o-theme-survey & {
        background-color: var(--colour-theme-survey);
    }
}

.c-calculator__subheading {
    margin: 0 auto;
    max-width: 750px;
}

/*
   Navigation
   ========================================================================== */

.c-calculator-nav {
    margin-top: -10px;
    width: 100%;

    ul {
        display: flex;
        margin: 0 -8px var(--grid-padding);
        padding: 0;
        list-style: none;
        width: calc(100% + 16px);

        @include break($mq-xl-min) {
            margin: 0 -8px var(--grid-padding-lg);
        }

        @include break($mq-xxl-min) {
            margin: 0 calc(var(--grid-padding-sm) * -1) var(--grid-padding-md);
            width: calc(100% + var(--grid-padding));
        }
    }
}

.c-calculator-nav__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 0 8px;
    padding: 10px;
    background-color: var(--colour-foxtrot-dark);
    border-radius: 32px;
    border: 2px solid var(--colour-white);
    font-size: responsive 18px 24px;
    font-weight: var(--font-weight-heavy);
    line-height: 1.2;
    text-align: center;
    color: var(--colour-foxtrot-x-dark);

    @include break($mq-xxl-min) {
        margin: 0 var(--grid-padding-sm);
        padding: 10px 20px;
    }
}

.c-calculator-nav__item--active {

    &.c-calculator-nav__item--energy {
        background-color: var(--colour-theme-energy);
        color: var(--colour-white);
    }

    &.c-calculator-nav__item--transport {
        background-color: var(--colour-theme-transport);
        color: var(--colour-white);
    }

    &.c-calculator-nav__item--food {
        background-color: var(--colour-theme-food);
        color: var(--colour-black);
    }

    &.c-calculator-nav__item--other {
        background-color: var(--colour-theme-other);
        color: var(--colour-black);
    }

    &.c-calculator-nav__item--survey {
        background-color: var(--colour-theme-survey);
        color: var(--colour-black);
    }
}
