/* ==========================================================================
   Footer
   ========================================================================== */

.c-footer {
    background: var(--colour-black)

    a {
        text-decoration: underline;
    }
}

.c-footer-sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: var(--grid-padding-xs) var(--grid-padding-sm);
    text-align: center;
    background-color: var(--colour-foxtrot-light);
    z-index: 222;

    p {
        margin: 0;
        font-size: 13px;
    }
}
