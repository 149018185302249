/* ==========================================================================
   Columns
   ========================================================================== */

.c-columns {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: var(--grid-padding);
    align-items: flex-start;

    @include break($mq-xl-min) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: var(--grid-padding-xl);
    }
}

.c-columns--calculator {
    display: flex;
    justify-content: space-between;
    grid-gap: 0;

    .c-column {
        width: 100%;
        margin: 0 auto;
        min-width: 60%;
        max-width: 700px;

        .o-text-centre {
            margin: 0 auto;
        }

        + .c-column {
            padding-left: 40px;
            width: 40%;
            min-width: 40%;

            @include break($mq-xl-min) {
                padding-left: 90px;  
            }
        }
    }
}

.c-column-image {
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--colour-grey-light);
    aspect-ratio: 53 / 43;

    img {
        aspect-ratio: 53 / 43;
    }
}

.c-column {
    display: flex;
    flex-flow: column;
    height: 100%;
}
