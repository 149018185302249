/* ==========================================================================
   Page
   ========================================================================== */

/*
  Page container
  ========================================================================== */

.o-page-container {
    position: relative;
    right: 0;
    overflow: hidden;
    transition: right var(--transition-duration) var(--transition-easing);

    &.o-page-container--nav-open {
        right: var(--mobile-nav-width);
    }
}

/*
   Page
   ========================================================================== */

.o-page {
    position: relative;
    z-index: 2;
}

/*
   Main
   ========================================================================== */

.o-page__main {
    width: 65%;
}

/*
   Content
   ========================================================================== */

.o-page__content {
    flex-grow: 1;
}

/*
   Media
   ========================================================================== */

.o-page__media {
    @include break($mq-md-min) {
        width: 66.6%;
    }
    @include break($mq-xxl-min) {
        width: 50%;
    }
}

/*
   Sidebar
   ========================================================================== */

.o-page__sidebar {
    width: 35%;
    @include break($mq-sm-max) {
        width: 100%;
        order: 2;
    }
    @include break-between($screen-lg-min, $screen-xl-max) {
        width: 30%;
    }
}
