/* ==========================================================================
   Header
   ========================================================================== */

.c-header {
    width: 100%;
    position: relative;

    + .o-wrapper-parent {
        margin-top: var(--grid-padding);
    }
}

.c-header__wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-image: url("../../../assets/svg/header-background.svg");
    background-repeat: no-repeat;
    background-size: cover;

    @include break($mq-sm-min) {
        background-size: contain;
    }

    &:before {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100% + -1px);
        background: var(--colour-header);
    }
}

/*
   Logos
   ========================================================================== */

.c-header-logos {
    flex: 1;
}


.c-header-logos__wrap {
    display: flex;
    align-items: center;
    height: 100%;
}

.c-header-logo {
    box-sizing: initial;
    z-index: 10;

    &--cyc {
        height: 50px;
        width: 111px;
    }

    &--kbt {
        height: 54px;
        width: 119px;
    }

    &--es {
        height: 63px;
        width: 61px;
    }

    + .c-header-logo {
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid var(--colour-alpha-dark);
    }
}

/*
   Profile
   ========================================================================== */

.c-header-profile {
    display: flex;
    align-items: center;
    padding-top: var(--grid-padding);
    padding-bottom: var(--grid-padding);
    z-index: 3;
}

.c-header-profile__detail {
    text-align: right;
    margin: 0 var(--grid-padding-sm) 0 0;
}

.c-header-profile__initial {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    background-color: var(--colour-alpha);
    color: var(--colour-white);
    border-radius: 50%;
    font-size: 26px;
    line-height: 26px;
    font-weight: var(--font-weight-bold);
    transition: background-color var(--transition-easing) var(--transition-duration);

    &:hover {
        cursor: pointer;
        color: var(--colour-white);
        background-color: var(--colour-alpha-dark);
    }

    &.is-active {
        background-color: var(--colour-alpha-dark);
        cursor: pointer;

        + .c-header-navigation {
            display: block;
        }
    }
}

.c-header-profile__user-name {
    display: block;
    text-decoration: underline;
    font-weight: var(--font-weight-bold);
}

.c-header-profile__school-name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;

    @include break($mq-lg-min) {
        max-width: 300px;
    }

    @include break($mq-xl-min) {
        max-width: 400px;
    }

    @include break($mq-xxl-min) {
        max-width: 500px;
    }
}

/*
   Navigation
   ========================================================================== */

.c-header-navigation__wrap {
    position: relative;
}

.c-header-navigation {
    display: none;
    position: absolute;
    right: 0;
    width: max-content;

    ul {
        margin: var(--grid-padding-sm) 0 0;
        padding: 20px 25px;
        list-style: none;
        background-color: var(--colour-white);
        border-radius: 6px;
    }

    li {
        + li {
            margin-top: var(--grid-padding-sm);
        }

        a {
            font-weight: var(--font-weight-bold);

            &:hover {
                color: var(--colour-alpha);
            }
        }
    }
}
