/* ==========================================================================
   Form defaults
   ========================================================================== */

$input-height-sm:   42px;
$input-height-md:   48px;

$input-colour:      var(--colour-grey-dark);

@mixin input {
    box-sizing: border-box;
    display: block;
    font-size: inherit;
    color: $input-colour;

    transition: var(--transition-duration) var(--transition-easing);
    transition-property: background-color, border;
    background-color: var(--colour-white);
    border-radius: 8px;
    border: 0;
    appearance: none;
    padding: var(--grid-padding-sm);

    .c-form-calculator & {
        border: 1px solid var(--colour-black);
    }

    &:focus {
        outline-color: var(--colour-bravo-dark);
        outline-style: solid;
        outline-width: 1px;
    }

    &:disabled {
        cursor: not-allowed !important;
        background-color: var(--colour-grey-light);
        border-color: var(--colour-grey-dark);
    }
}

/*
   Inputs
   ========================================================================== */

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="date"],
input[type="url"] {
    @include input;
    width: 100%;
    line-height: 1;
}


/*
   Checkboxes / Radio buttons
   ========================================================================== */

input[type="radio"],
input[type="checkbox"] {
    @include input;
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
    cursor: pointer;
    padding: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75% auto;
    transition: none;

    & + label {
        margin: 0;
        font-size: 15px;
        display: inline-block;
        vertical-align: middle;
    }
}

input[type="radio"] {
    border-radius: 50%;
    border-style: solid;
    border-width: 6px !important;
    border-color: transparent !important;

    &:checked {
        border-color: var(--colour-white) !important;
        background-color: var(--colour-black);
    }
}

input[type="checkbox"] {
    border-radius: 2px;
    margin-right: 7px;

    &:checked {
        background-image: url("../../../assets/svg/icons/checkbox.svg");
    }
}


/*
   Textarea
   ========================================================================== */

textarea {
    @include input;
    padding: var(--grid-padding-sm);
    display: block;
    resize: vertical;
    overflow: auto;
    max-height: 100px;
}


/*
   Select
   ========================================================================== */

select {
    @include input;
    width: 100%;
    line-height: 1;
    background-image: url("../../../assets/svg/icons/select-arrow.svg");
    background-repeat: no-repeat;
    background-size: 12px 8px;
    background-position: 96% 50%;
    padding: calc(var(--grid-padding-sm) + 2px) 24px var(--grid-padding-sm) var(--grid-padding-sm);

    &:hover {
        cursor: pointer;
    }

    /* sass-lint:disable-all */
    &::-ms-expand {
        display: none;
    }

    &:focus::-ms-value {
        color: $input-colour;
        background: none;
    }
    /* sass-lint:enable-all */
}
